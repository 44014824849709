import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

import { UserInfo } from '@ppg/core/models';

import { Dictionary } from '../models/dictionary.model';
import { GoogleAnalyticsEventAction } from '../models/ga-event-action.enum';
import { GoogleAnalyticsEventCategory } from '../models/ga-event-category.enum';
import { GoogleAnalyticsEvent } from '../models/ga-event.enum';

interface LoginTagStructure {
  event: GoogleAnalyticsEvent;
  eventCategory: GoogleAnalyticsEventCategory;
  eventAction: string;
  eventLabel: string;
  userInformation: UserInformation;
  brand: string;
  countryCode: string;
}

interface FireTagStructure {
  event: GoogleAnalyticsEvent;
  pageInformation: PageInformation;
  siteInformation: SiteInformation;
  userInformation: UserInformation;
}

interface PageInformation {
  pageCategory: string;
}

interface SiteInformation {
  country: string;
  businessUnit: string;
  brand: string;
  language: string;
  platform: 'CDE';
  siteType: string;
}

interface UserInformation {
  loginStatus: 'loggedIn' | 'loggedOut';
  ppgAccountId?: string;
  accountType?: string;
  userId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class CustomGtmTagService {
  constructor(private gtmService: GoogleTagManagerService) {}

  #initBuffer: object[] = [];
  #initialized = false;

  initialize(): void {
    this.#initialized = true;
    this.gtmService.getDataLayer().splice(0, 0, ...this.#initBuffer);
    this.gtmService.addGtmToDom();
  }

  pushTag(item: object): void {
    if (!this.#initialized) {
      this.#initBuffer.push(item);
      return;
    }
    this.gtmService.pushTag(item);
  }

  pushTagToGtm(eventName: string, t: string, d1?: string, d2?: string, d3?: string, ...restOfParameters: Dictionary[]) {
    const tag = this.generateTagStructure(eventName, t, d1, d2, d3, ...restOfParameters);
    this.pushTag(tag);
  }

  pushLoginTagToGtm(userInfo: UserInfo, brandName: string, countryCode: string): void {
    const loginTag: LoginTagStructure = {
      event: GoogleAnalyticsEvent.Login,
      eventCategory: GoogleAnalyticsEventCategory.Account,
      eventAction: GoogleAnalyticsEventAction.LoginSuccessful,
      eventLabel: 'email',
      userInformation: this.getUserInformation(userInfo),
      brand: brandName,
      countryCode: countryCode,
    };
    this.pushTag(loginTag);
  }

  pushHistoryChangedTagToGtm(
    userInfo: UserInfo | null,
    pageCategory: string,
    language: string,
    brandName: string,
    countryCode: string,
    businessUnit: string,
  ): void {
    const fireTag: FireTagStructure = {
      event: GoogleAnalyticsEvent.FireTags,
      pageInformation: {
        pageCategory: pageCategory,
      },
      siteInformation: {
        country: countryCode,
        businessUnit: businessUnit,
        brand: brandName,
        language: language,
        platform: 'CDE',
        siteType: 'Site',
      },
      userInformation: this.getUserInformation(userInfo),
    };
    this.pushTag(fireTag);
  }

  pushEcommerceTagToGtm(eventName: string, items: object[], ecommerceInfo?: object, info?: object) {
    this.pushTag({
      event: eventName,
      ...info,
      ecommerce: {
        ...ecommerceInfo,
        items: [...items],
      },
    });
  }

  private getUserInformation(userInfo: UserInfo | null): UserInformation {
    return {
      loginStatus: userInfo ? 'loggedIn' : 'loggedOut',
      userId: userInfo?.userKey,
      accountType: userInfo?.roleName,
      ppgAccountId: userInfo?.ppgParentAccountNumber,
    };
  }

  private generateTagStructure(
    eventName: string,
    t: string,
    d1?: string,
    d2?: string,
    d3?: string,
    ...restOfParameters: Dictionary[]
  ): object {
    return {
      event: eventName,
      'gtm.element': {
        dataset: { t: t, d1: d1 ?? '', d2: d2 ?? '', ...(d3 && { d3 }) },
        ...restOfParameters.reduce((acc, rec) => ({ ...acc, [rec.key]: rec.value }), {}),
      },
    };
  }
}
