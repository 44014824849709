export enum GoogleAnalyticsEvent {
  FireTags = 'fireTags',
  Login = 'login',
  AddToCart = 'add_to_cart',
  BeginCheckout = 'begin_checkout',
  Purchase = 'purchase',
  RemoveFromCart = 'remove_from_cart',
  SelectItem = 'select_item',
  SelectSku = 'select_sku',
  ViewCart = 'view_cart',
  ViewItem = 'view_item',
  ViewItemList = 'view_item_list',
  CustomEvent = 'customEvent',
  TrackEvent = 'trackEvent',
  RegistrationStart = 'registration_start',
  RegistrationComplete = 'registration_complete',
  RegistrationAttempt = 'registration_attempt',
  RegistrationStep = 'registration_step',
  RegistrationStepError = 'registration_step_error',
  RegistrationError = 'registration_error',
  AccountNumberHelp = 'account_number_help',
  EmailValidated = 'email_validated',
  EmailAlreadyValidated = 'email_already_validated',
}
