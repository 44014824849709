import { APP_INITIALIZER, EnvironmentProviders, inject, Injector, makeEnvironmentProviders } from '@angular/core';

import { AnalyticsService } from '../../services/analytics.service';

export function provideAnalytics(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: () => {
        const injector = inject(Injector);
        return () => injector.get(AnalyticsService).init();
      },
    },
  ]);
}
