export enum GoogleAnalyticsEventCategory {
  ECommerce = 'eCommerce',
  Error = 'error',
  Account = 'account',
  DataSheets = 'data-sheets',
  Favorites = 'favorites',
  Contacts = 'contacts',
  Orders = 'orders',
  Search = 'search',
  Jobs = 'jobs',
  Job = 'job',
}
