import { isPlatformBrowser } from '@angular/common';
import { DestroyRef, effect, inject, Injectable, Injector, PLATFORM_ID, runInInjectionContext } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Event, EventType, NavigationEnd, Router } from '@angular/router';
import { filter, map, of, switchMap } from 'rxjs';

import { AuthService, UserService } from '@ppg/auth';
import { CurrentSiteState } from '@ppg/configuration';
import { LanguagesService } from '@ppg/core/language';

import { CustomGtmTagService } from './custom-gtm-tag.service';
import { AnalyticsProvider } from '../providers/analytics-provider.model';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService implements AnalyticsProvider {
  readonly #isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
  readonly #router = inject(Router);
  readonly #destroyRef = inject(DestroyRef);
  readonly #injector = inject(Injector);
  readonly #authService = inject(AuthService);
  readonly #userService = inject(UserService);
  readonly #gtmService = inject(CustomGtmTagService);
  readonly #langulagesService = inject(LanguagesService);
  readonly #currentSiteState = inject(CurrentSiteState);
  readonly #activatedRoute = inject(ActivatedRoute);

  #initialized = false;

  readonly getLastChildRoute = (activatedRoute: ActivatedRoute) => {
    let child = activatedRoute.firstChild;
    if (child) {
      while (child.firstChild) {
        child = child.firstChild;
      }
    }
    return child;
  };

  init(): void {
    if (this.#initialized) {
      return;
    }
    this.#initialized = true;

    if (!this.#isBrowser) {
      return;
    }
    this.#router.events
      .pipe(
        filter((event: Event): event is NavigationEnd => event.type === EventType.NavigationEnd),
        map((nav: NavigationEnd) => {
          const activeRouteChild = this.#activatedRoute;
          if (activeRouteChild) {
            const child = this.getLastChildRoute(activeRouteChild);
            if (child && child.snapshot.data && child.snapshot.data['gtm']) {
              return { url: nav.urlAfterRedirects, ...child.snapshot.data['gtm'] };
            } else {
              return { url: nav.urlAfterRedirects };
            }
          }
          return null;
        }),
        switchMap((data) =>
          this.#authService.isAuthenticated()
            ? this.#userService.loaded$.pipe(
                filter(Boolean),
                map(() => data),
              )
            : of(data),
        ),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe((activeRoute) => {
        if (activeRoute) {
          const isAuthenticated = this.#authService.isAuthenticated();
          const user = this.#userService.user();
          const language = this.#langulagesService.language();
          const brandName = this.#currentSiteState.currentSite()?.name ?? '';
          const countryCode = this.#langulagesService.countryCode() ?? '';
          const businessUnit = this.#currentSiteState.currentSite()?.businessUnit ?? '';

          if (!isAuthenticated || !!user) {
            this.#gtmService.pushHistoryChangedTagToGtm(
              user,
              activeRoute.getPageCategory !== undefined ? activeRoute.getPageCategory(activeRoute.url) : 'other',
              language,
              brandName,
              countryCode,
              businessUnit,
            );
            this.#gtmService.initialize();
          }
        }
      });

    runInInjectionContext(this.#injector, () => {
      const pushLoginTagToGtmEffectRef = effect(() => {
        const user = this.#userService.user();
        const language = this.#langulagesService.language();
        const brandName = this.#currentSiteState.currentSite()?.name ?? '';
        const countryCode = this.#langulagesService.countryCode() ?? '';

        if (user && language) {
          this.#gtmService.pushLoginTagToGtm(user, brandName, countryCode);
          pushLoginTagToGtmEffectRef.destroy();
        }
      });
    });
  }
}
