export enum GoogleAnalyticsEventAction {
  RepAccountLookupCustomer = 'rep_account_lookup_customer_action',
  RepAccountLookupCustomerSuccess = 'rep_account_lookup_customer_success',
  RepAccountLookupCustomerFail = 'rep_account_Lookup_customer_fail',
  RepAccountLookupCustomerError = 'rep_account_lookup_customer_error',
  RepAccountLookupCustomerUnexpectedError = 'rep_account_lookup_customer_unexpected_error',

  AddToFavoritesQuick = 'add_to_favorites_quick',
  RemoveFromFavoritesQuick = 'remove_from_favorites_quick',

  AddToFavoritesProductSearch = 'add_to_favorites_productSearch',
  RemoveFromFavoritesProductSearch = 'remove_from_favorites_productSearch',

  AddToFavoritesProductCatalog = 'add_to_favorites_productCatalog',
  RemoveFromFavoritesProductCatalog = 'remove_from_favorites_productCatalog',

  InviteUserClick = 'invite_user_click',
  DeactivateUserConfirmed = 'user_account_deactivated_confirmed',
  ReactivateUserConfirmed = 'user_account_reactivated_confirmed',

  LoginSuccessful = 'login_successful',
  ViewDataSheets = 'view-data-sheets',
  EditUserSaved = 'edit_user_saved',
  RedirectToUsersFromEmailAdmin = 'redirect_to_users_from_email_admin',
  UpdateUnverifiedUserRoleAdmin = 'update_unverified_user_role_admin',
  UpdateUnverifiedUserRoleRepStore = 'update_unverified_user_role_repstore',
  RemoveFromFavoritesPage = 'remove_from_favorites_page',
  ReactivateCustomerAccountConfirmed = 'customer_account_reactivated_confirmed',
  ReactivateJobAccountConfirmed = 'job_account_reactivated_confirmed',
  RedirectToUsersFromEmailRepStore = 'redirect_to_users_from_email_repstore',

  FulfillmentContactAdded = 'fulfillment_contact_added',
  FulfillmentContactEdited = 'fulfillment_contact_edited',
  FulfillmentContactDeleted = 'fulfillment_contact_deleted',

  ExportOnlineOrderHistoryError = 'export_online_order_history_error',
  ExportOnlineOrderHistorySuccess = 'export_online_order_history_success',
  ExportOnlineOrderHistoryInitiated = 'export_online_order_history_initiated',
  ExportOnlineOrderHistoryButtonClicked = 'export_online_order_history_button_clicked',

  EAccountClicked = 'eAccount_click',

  SearchSuggestionUsed = 'search_suggestion_used',
  SearchSuggestionRedirect = 'search_suggestion_redirect',

  RequestAccessToJob = 'jobs_request_access',

  ReOrderAllClick = 'add_to_cart_reorder_all',

  SaveCreditCardStart = 'save_credit_card_start',
  SaveCreditCardComplete = 'save_credit_card_complete',

  CashCustomerRegistrationResendCode = 'registration_ccust_new_sec_code',
  CashCustomerRegistrationSubmitSecurityCode = 'registration_ccust_security_attempt',
  CashCustomerRegistrationStart = 'registration_ccust_start',
  CashCustomerRegistrationLeadGeneration = 'registration_ccust_email_opt_in_leadgen',
  CashCustomerRegistrationCompletedFirstStep = 'registration_ccust_completed_step_1',
  CashCustomerRegistrationVerifyEmail = 'registration_ccust_completed_step_2',
  CashCustomerRegistrationSubmitCompanyInfo = 'registration_ccust_completed_step_3',
  CashCustomerRegistrationAttempt = 'ccust_registration_attempt',
  CashCustomerRegistrationReview = 'registration_ccust_completed_step_4',
  CashCustomerRegistrationCompleted = 'ccust_registration_completed',
  CashCustomerRegistrationEmptyCode = 'registration_ccust_completed_step_2_error',
  CashCustomerRegistrationError = 'completed_ccust_registration_error',

  PaymentTypeCreditCard = 'payment_type_credit card',
  PaymentTypePPGCredit = 'payment_type_ppgcredit',

  AddNewJobNameOpened = 'add_new_job_name_Opened',
  AddNewJobNameSubmitInitiated = 'add_new_job_name_submit_initated',
  AddNewJobNameSubmitSucesss = 'add_new_job_name_submit_success',
  AddNewJobNameSubmitFailed = 'add_new_job_name_submit_failed',

  JobAddCancel = 'job_add_cancel',
  JobAddStart = 'job_add_start',
  JobAddFirstStep = 'job_add_job_info_step_1',
  JobAddSecondStep = 'job_add_assigned_addresses_step_2',
  JobAddThirdStep = 'job_add_assigned_users_step_3',
  JobAddSubmit = 'job_add_submit',
  JobAddSubmitInitiated = 'job_add_submit_initiated',
  JobAddSubmitSuccessful = 'job_add_new_job_successful',

  JobEditStart = 'job_edit_start',
  JobEditCancel = 'job_edit_cancel',
  JobEditFirstStep = 'job_edit_job_info_step_1',
  JobEditSecondStep = 'job_edit_assigned_addresses_step_2',
  JobEditThirdStep = 'job_edit_assigned_users_step_3',
  JobEditSubmit = 'job_edit_submit',
  JobEditSubmitInitiated = 'job_edit_submit_initiated',
  JobEditSubmitSuccessful = 'job_edit_successful',

  RegistrationStart = 'registration_start',
  RegistrationAttempt = 'registration_attempt',
  RegistrationError = 'completed_registration_error',
  RegistrationComplete = 'account_link_request_complete',
  RegistrationCompleteStep = 'registration_completed_step_2',
  AccountNumberHelp = 'account_number_help',
  EmailValidated = 'email_validated',
  EmailAlreadyValidated = 'email_already_validated',

  B2CCustomerRegistrationStart = 'registration_b2ccust_start',
  B2CCustomerRegistrationCompletedFirstStep = 'registration_b2ccust_completed_step_1',
  B2CCustomerRegistrationStep2Error = 'registration_b2ccust_Completed_step_2_error',
  B2CCustomerRegistrationResendCode = 'registration_b2ccust_new_sec_code',
  B2CCustomerRegistrationSubmitSecurityCode = 'registration_b2ccust_security_attempt',
  B2CCustomerRegistrationVerifyEmail = 'registration_b2ccust_Completed_step_2',
  B2CCustomerRegistrationCompleted = 'b2ccust_registration_completed',

  ViewInvoiceOnline = 'view_invoice_online',
  ViewInvoiceInStore = 'view_invoice_instore',
}
